import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
} from '@mui/x-data-grid';
import { GridRowParams } from '@mui/x-data-grid/models/params/gridRowParams';
import { useSnackbar } from 'notistack';
import * as React from 'react';
import { useEffect, useState } from 'react';
import DataList from '../../components/DataList';
import useCheckPermission from '../../hooks/useCheckPermission';
import useConfirmDeleteDialog from '../../hooks/useConfirmDeleteDialog';
import { formatCurrency } from '../../utils/valueFormatters';
import AddNewItemDialog from './AddNewItemDialog';
import EditItemDialog from './EditItemDialog';
import { CartItem } from './SalesPage';
import SelectServiceDialog from './SelectServiceDialog';
import { useBarcodeScanner } from '../../components/barcode-scanner/useBarcodeScanner';

export default function AddnewItemPackageDialog(props: any) {
  const {
    open,
    setOpen,
    addToCart,
    id = 0,
    items,
    cart,
    selectUnpriced = false,
    disableAmountCheck = false,
    taxes,
    getAvailableAmount,
    workItem,
    canOverrideGrossPrice = false,
    allServices,
    discount,
  } = props;

  const { ConfirmDeleteDialog, setParams } = useConfirmDeleteDialog();
  const [addItemDialogOpen, setAddItemDialogOpen] = useState<boolean>(false);
  const [addServiceDialog, setAddServiceDialog] = useState<any>({
    open: false,
    serviceToEdit: null,
  });
  const [editItemDialogOpen, setEditItemDialogOpen] = useState({
    open: false,
    row: {
      itemId: 0,
      itemNumber: '',
      itemName: '',
      amount: 0,
      discount: 0,
      sellPriceGross: 0,
      sellPriceNet: 0,
      taxTypeId: 1,
      comment: '',
      isComplexItem: false,
      parts: [],
    },
  });

  const [itemPackage, setItemPackage] = React.useState({
    id: id,
    name: 'Termékcsomag ' + id,
    amount: 1,
    discount: 0,
    sellPriceGross: 0,
    sellPriceNet: 0,
    taxTypeId: 1,
    comment: '',
    items: [],
    services: [],
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setItemPackage({
      id: id,
      name: 'Termékcsomag ' + id,
      amount: 1,
      discount: 0,
      sellPriceGross: 0,
      sellPriceNet: 0,
      taxTypeId: 1,
      comment: '',
      items: [],
      services: [],
    });
    setOpen(false);
  };

  useEffect(() => {
    setItemPackage({
      ...itemPackage,
      id: id,
      name: 'Termékcsomag ' + id,
    });
  }, [id]);

  useEffect(() => {
    let sellPriceGross = 0;
    let sellPriceNet = 0;
    if (itemPackage.items.length > 0) {
      sellPriceGross += itemPackage.items.reduce(
        (a, b) =>
          a +
          b.sellPriceGross *
            b.amount *
            (b.discount !== 0 ? 1 - b.discount / 100 : 1) *
            (discount !== 0 ? 1 - discount / 100 : 1),
        0
      );
      sellPriceNet += itemPackage.items.reduce(
        (a, b) =>
          a +
          b.sellPriceNet *
            b.amount *
            (b.discount !== 0 ? 1 - b.discount / 100 : 1) *
            (discount !== 0 ? 1 - discount / 100 : 1),
        0
      );
    }

    if (itemPackage.services.length > 0) {
      sellPriceGross += itemPackage.services.reduce(
        (a, b) =>
          a +
          b.servicePriceGross *
            b.amount *
            (b.discount !== 0 ? 1 - b.discount / 100 : 1) *
            (discount !== 0 ? 1 - discount / 100 : 1),
        0
      );
      sellPriceNet += itemPackage.services.reduce(
        (a, b) =>
          a +
          b.servicePriceNet *
            b.amount *
            (b.discount !== 0 ? 1 - b.discount / 100 : 1) *
            (discount !== 0 ? 1 - discount / 100 : 1),
        0
      );
    }
    setItemPackage({
      ...itemPackage,
      sellPriceGross: sellPriceGross,
      sellPriceNet: sellPriceNet,
    });
  }, [itemPackage.items, itemPackage.services]);

  const { checkPermission } = useCheckPermission();

  const columns: GridColDef[] = [
    {
      field: 'itemNumber',
      headerName: 'Cikkszám',
      flex: 100,
      editable: false,
    },
    {
      field: 'itemName' || 'name',
      headerName: 'Termék',
      flex: 100,
      editable: false,
    },
    {
      field: 'sellPriceNet',
      headerName: 'Nettó egységár',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
      editable: false,
    },
    {
      field: 'sellPriceGross',
      headerName: 'Bruttó egységár',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
      editable: false,
    },
    {
      field: 'discount',
      headerName: 'Engedmény',
      flex: 100,
      editable: false,
      valueFormatter: (params) => params.value + '%',
    },
    {
      field: 'amount',
      headerName: 'Mennyiség',
      editable: false,
      flex: 100,
    },
    {
      field: 'grossPrice',
      headerName: 'Bruttó ár',
      flex: 100,
      valueFormatter: (params) => formatCurrency(params.value),
      editable: false,
    },
    {
      field: 'taxTypeId',
      headerName: 'ÁFA',
      flex: 100,
      editable: false,
      valueGetter: (params) => {
        const taxTypeName =
          taxes.find((x) => x.id === params.row.taxTypeId)?.name ?? '';
        const formattedTaxTypeName = isNaN(Number(taxTypeName))
          ? taxTypeName
          : `${taxTypeName}%`;
        return formattedTaxTypeName;
      },
    },
    {
      field: 'comment',
      headerName: 'Megjegyzés',
      editable: false,
      flex: 100,
    },
    {
      field: 'actions',
      type: 'actions',
      flex: 100,
      disableExport: true,
      getActions: (params: GridRowParams, color: any) => [
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Szerkesztés">
              <EditIcon />
            </Tooltip>
          }
          label={'Szerkesztés'}
          onClick={() => {
            params.row.isService
              ? setAddServiceDialog({
                  open: true,
                  serviceToEdit:
                    itemPackage.services.find(
                      (x) => x.id === params.row.itemId
                    ) ?? null,
                })
              : setEditItemDialogOpen({
                  open: true,
                  row: {
                    itemId: params.row.itemId,
                    itemName: params.row.itemName,
                    amount: params.row.amount,
                    discount: params.row.discount,
                    itemNumber: params.row.itemNumber,
                    sellPriceGross: params.row.sellPriceGross,
                    sellPriceNet: params.row.sellPriceNet,
                    taxTypeId: params.row.taxTypeId,
                    comment: params.row.comment ?? '',
                    isComplexItem: params.row.isComplexItem,
                    parts: params.row.parts,
                  },
                });
          }}
        />,
        <GridActionsCellItem
          color={color ? color : 'primary'}
          icon={
            <Tooltip title="Törlés">
              <DeleteIcon />
            </Tooltip>
          }
          label="Delete"
          onClick={() =>
            setParams({
              open: true,
              name: 'Biztosan törölni szeretné?',
              onConfirm: async () => {
                handleDelete(params.row.itemId, params.row.isService);
              },
            })
          }
        />,
      ],
    } as GridActionsColDef,
  ];

  const handleDelete = (id: number, isService: boolean) => {
    if (isService) {
      setItemPackage({
        ...itemPackage,
        services: itemPackage.services.filter((g) => g.id !== id),
      });
    } else {
      setItemPackage({
        ...itemPackage,
        items: itemPackage.items.filter((g) => g.itemId !== id),
      });
    }
  };

  const addCartItem = (item: CartItem) => {
    var found = itemPackage.items.find((x) => x.itemId === item.itemId);
    if (found) {
      if (
        getAvailableAmount(item.itemId, [...cart, itemPackage.items ?? []]) >=
          item.amount ||
        selectUnpriced
      ) {
        setItemPackage({
          ...itemPackage,
          items: itemPackage.items.map((x) =>
            x.itemId === item.itemId
              ? { ...x, amount: x.amount + item.amount }
              : x
          ),
        });

        setAddItemDialogOpen(false);
        return;
      }
    } else {
      if (
        getAvailableAmount(item.itemId, [...cart, itemPackage.items ?? []]) >=
          item.amount ||
        selectUnpriced
      ) {
        setItemPackage({
          ...itemPackage,
          items: [...itemPackage.items, item],
        });
        setAddItemDialogOpen(false);
        return;
      }
    }

    enqueueSnackbar('Nem áll rendelkezésre a kívánt mennyiség!', {
      variant: 'error',
    });
  };

  const processEditItem = (cartItem: CartItem) => {
    setItemPackage({
      ...itemPackage,
      items: itemPackage.items.map((x) =>
        x.itemId === cartItem.itemId ? cartItem : x
      ),
    });
  };

  const getItemPriceGross = (itemId: number) => {
    return (
      itemPackage.items.find((x) => x.itemId === itemId)?.sellPriceGross ?? 0
    );
  };

  const getItemPriceNet = (itemId: number, taxTypeId: number) => {
    return itemId < 0
      ? itemPackage.items.find((x) => x.itemId === itemId)?.sellPriceNet ?? 0
      : getItemPriceGross(itemId) /
          (1 + (taxes.find((x) => x.id === taxTypeId)?.value ?? 27) / 100);
  };

  const itemsAndServices = [
    ...itemPackage.items.map((x) => ({
      ...x,
      isService: false,
      comment: x.comment ?? '',
      itemNumber:
        items.find((y) => y.itemId === x.itemId)?.itemNumber ??
        x.itemNumber ??
        '',
      name:
        items.find((y) => y.itemId === x.itemId)?.itemName ?? x.itemName ?? '',
      sellPriceGross:
        x.itemId > 0 ? x.sellPriceGross : getItemPriceGross(x.itemId),
      sellPriceNet:
        x.itemId > 0 ? x.sellPriceNet : getItemPriceNet(x.itemId, x.taxTypeId),
      grossPrice:
        (x.itemId > 0 ? x.sellPriceGross : getItemPriceGross(x.itemId)) *
        x.amount *
        (x.discount !== 0 ? 1 - x.discount / 100 : 1) *
        (discount !== 0 ? 1 - discount / 100 : 1),
    })),
    ...itemPackage.services.map((y) => ({
      isService: true,
      itemId: y.id,
      itemName: y.name,
      comment: y.comment,
      amount: y.amount,
      discount: y.discount,
      itemNumber: '',
      sellPriceGross: y.servicePriceGross,
      sellPriceNet: y.servicePriceNet,
      grossPrice:
        y.servicePriceGross *
        y.amount *
        (y.discount !== 0 ? 1 - y.discount / 100 : 1) *
        (discount !== 0 ? 1 - discount / 100 : 1),
      taxTypeId: y.taxTypeId,
    })),
  ];

  const handleBarcodeRead = (barcode) => {
    if (barcode.item) {
      addCartItem({
        ...barcode.item,
        amount: 1,
        discount: 0,
        taxTypeId: taxes[0].id,
        sellPriceNet: barcode.item?.net,
        sellPriceGross: barcode.item?.gross,
        comment: '',
      });
    } else {
      enqueueSnackbar('Ismeretlen árucikk', {
        variant: 'error',
      });
    }
  };

  useBarcodeScanner(handleBarcodeRead, items);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={'xl'} fullWidth>
      <DialogTitle>Termék csomag felvétele</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} style={{ paddingTop: 10 }}>
          <Grid item xs={12}>
            <TextField
              value={itemPackage.name}
              onChange={(event) => {
                setItemPackage({ ...itemPackage, name: event.target.value });
              }}
              label="Csomag neve"
              name="itemName"
              size="small"
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              value={itemPackage.amount}
              onChange={(event) => {
                let amount = parseFloat(event.target.value);
                setItemPackage({ ...itemPackage, amount: amount });
              }}
              label="Darabszám"
              name="amount"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">db</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={itemPackage.sellPriceNet?.toFixed(0)}
              onChange={(event) => {
                let sellPriceNet = parseFloat(event.target.value);
                if (!sellPriceNet) {
                  sellPriceNet = 0;
                }
                let sumOfItems = itemPackage.items.reduce(
                  (a, b) =>
                    a +
                    b.sellPriceNet *
                      b.amount *
                      (b.discount !== 0 ? 1 - b.discount / 100 : 1),
                  0
                );
                let sumOfServices = itemPackage.services.reduce(
                  (a, b) =>
                    a +
                    b.servicePriceNet *
                      b.amount *
                      (b.discount !== 0 ? 1 - b.discount / 100 : 1),
                  0
                );
                let sum = sumOfItems + sumOfServices;
                let multiplier = sellPriceNet / (sum === 0 ? 1 : sum);
                setItemPackage({
                  ...itemPackage,
                  sellPriceNet: sellPriceNet,
                  sellPriceGross:
                    sellPriceNet *
                    (1 +
                      (taxes?.find((x) => x.id === itemPackage.taxTypeId)
                        ?.value ?? 27) /
                        100),
                  items: itemPackage.items.map((x) => ({
                    ...x,
                    sellPriceGross:
                      (x.sellPriceGross === 0
                        ? 1 +
                          (taxes?.find((c) => c.id === x.taxTypeId)?.value ??
                            27) /
                            100
                        : x.sellPriceGross) * multiplier,
                    sellPriceNet:
                      (x.sellPriceNet === 0 ? 1 : x.sellPriceNet) * multiplier,
                  })),
                  services: itemPackage.services.map((x) => ({
                    ...x,
                    servicePriceGross:
                      (x.servicePriceGross === 0
                        ? 1 +
                          (taxes?.find((c) => c.id === x.taxTypeId)?.value ??
                            27) /
                            100
                        : x.servicePriceGross) * multiplier,
                    servicePriceNet:
                      (x.servicePriceNet === 0 ? 1 : x.servicePriceNet) *
                      multiplier,
                  })),
                });
              }}
              label="Nettó ár"
              name="sellPriceNet"
              InputLabelProps={{ shrink: true }}
              size="small"
              disabled={!canOverrideGrossPrice}
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Ft</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={itemPackage.sellPriceGross?.toFixed(0)}
              onChange={(event) => {
                let sellPriceGross = parseFloat(event.target.value);
                if (!sellPriceGross) {
                  sellPriceGross = 0;
                }
                let sumOfItems = itemPackage.items.reduce(
                  (a, b) =>
                    a +
                    b.sellPriceGross *
                      b.amount *
                      (b.discount !== 0 ? 1 - b.discount / 100 : 1),
                  0
                );
                let sumOfServices = itemPackage.services.reduce(
                  (a, b) =>
                    a +
                    b.servicePriceGross *
                      b.amount *
                      (b.discount !== 0 ? 1 - b.discount / 100 : 1),
                  0
                );
                let sum = sumOfItems + sumOfServices;
                let multiplier = sellPriceGross / (sum === 0 ? 1 : sum);
                setItemPackage({
                  ...itemPackage,
                  sellPriceGross: sellPriceGross,
                  sellPriceNet:
                    sellPriceGross /
                    (1 +
                      (taxes?.find((x) => x.id === itemPackage.taxTypeId)
                        ?.value ?? 27) /
                        100),
                  items: itemPackage.items.map((x) => ({
                    ...x,
                    sellPriceGross:
                      (x.sellPriceGross === 0 ? 1 : x.sellPriceGross) *
                      multiplier,
                    sellPriceNet:
                      (x.sellPriceNet === 0
                        ? 1 /
                          (1 +
                            (taxes?.find((c) => c.id === x.taxTypeId)?.value ??
                              27) /
                              100)
                        : x.sellPriceNet) * multiplier,
                  })),
                  services: itemPackage.services.map((x) => ({
                    ...x,
                    servicePriceGross:
                      (x.servicePriceGross === 0 ? 1 : x.servicePriceGross) *
                      multiplier,
                    servicePriceNet:
                      (x.servicePriceNet === 0
                        ? 1 /
                          (1 +
                            (taxes?.find((c) => c.id === x.taxTypeId)?.value ??
                              27) /
                              100)
                        : x.servicePriceNet) * multiplier,
                  })),
                });
              }}
              label="Bruttó ár"
              name="sellPriceGross"
              disabled={!canOverrideGrossPrice}
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Ft</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={itemPackage.discount}
              onChange={(event) => {
                let discount = parseFloat(event.target.value);
                if (discount >= 0 && discount <= 100) {
                  setItemPackage({ ...itemPackage, discount: discount });
                }
              }}
              label="Kedvezmény"
              name="discount"
              size="small"
              fullWidth
              type="number"
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              disablePortal
              id="taxTypeId"
              value={itemPackage.taxTypeId ?? ''}
              onChange={(event, value: number) => {
                let newItems = itemPackage.items.map((x) => ({
                  ...x,
                  taxTypeId: value,
                  sellPriceGross:
                    x.sellPriceNet *
                    (1 +
                      (taxes?.find((g) => g.id === value)?.value ?? 27) / 100),
                }));
                let newServices = itemPackage.services.map((x) => ({
                  ...x,
                  taxTypeId: value,
                  servicePriceGross:
                    x.servicePriceNet *
                    (1 +
                      (taxes?.find((g) => g.id === value)?.value ?? 27) / 100),
                }));
                setItemPackage({
                  ...itemPackage,
                  items: newItems,
                  services: newServices,
                  taxTypeId: value,
                  sellPriceGross:
                    itemPackage.sellPriceNet *
                    (1 +
                      (taxes?.find((x) => x.id === value)?.value ?? 27) / 100),
                });
              }}
              getOptionLabel={(option) =>
                taxes?.find((g) => g.id === option)?.name ?? ''
              }
              options={taxes?.map((g) => g.id)}
              renderInput={(params) => (
                <TextField {...params} required fullWidth label="ÁFA típusa" />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              value={itemPackage.comment}
              onChange={(event) => {
                setItemPackage({ ...itemPackage, comment: event.target.value });
              }}
              label="Megjegyzés"
              name="comment"
              multiline
              minRows={2}
              fullWidth
            />
          </Grid>
          <Grid container item xs={12} p={2} justifyContent="end">
            <Grid item>
              <Grid item textAlign={'right'}>
                <Tooltip title="Szolgáltatás hozzáadása">
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setAddServiceDialog({ open: true, serviceToEdit: null })
                    }
                  >
                    <PersonAddAlt1Icon />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item>
              <Tooltip title="Termék hozzáadása">
                <IconButton
                  color="primary"
                  onClick={() => setAddItemDialogOpen(true)}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            {/* <Grid item>
              <Tooltip title="Egyedi tétel hozzáadása">
                <IconButton
                  color="primary"
                  onClick={() => {
                    let id = cart.map((x) => x.orderId < 0).length + 1;
                    dispatch(
                      addToCart({
                        id: -id,
                        itemId: -id,
                        itemName: 'Gyüjtő ' + id,
                        itemNumber: 'A-' + id.toString().padStart(3, '0'),
                        amount: 1,
                        discount: 0,
                        sellPriceNet: 0,
                        sellPriceGross: 0,
                        taxTypeId: taxes[0].id,
                        comment: '',
                      })
                    );
                  }}
                >
                  <Create />
                </IconButton>
              </Tooltip>
            </Grid> */}
          </Grid>
          <Grid item xs={12}>
            <DataList
              rows={itemsAndServices}
              localStorageKey={'SalesPage'}
              columns={columns}
              getRowId={(row) => `${row.isService}-${row.itemId}`}
              isCellEditable={(params) => {
                return (
                  params.row.itemId < 0 ||
                  params.field === 'amount' ||
                  params.field === 'discount'
                );
              }}
              exportFields={[
                'itemName',
                'itemNumber',
                'sellPriceNet',
                'sellPriceGross',
                'amount',
                'discount',
              ]}
            />{' '}
            <AddNewItemDialog
              open={addItemDialogOpen}
              setOpen={setAddItemDialogOpen}
              getAvailableAmount={getAvailableAmount}
              items={items}
              cart={[...cart, itemPackage.items ?? []]}
              taxes={taxes}
              externalTaxTypeId={itemPackage.taxTypeId}
              canAddCustomItem={false}
              disableAmountCheck={disableAmountCheck}
              addToCart={(item: any) => {
                addCartItem(item);
              }}
              selectUnpriced={selectUnpriced}
              canOverrideGrossPrice={true}
            />
            <EditItemDialog
              open={editItemDialogOpen.open}
              setOpen={setEditItemDialogOpen}
              getAvailableAmount={(itemId: any) => {
                return getAvailableAmount(itemId, [
                  ...cart,
                  itemPackage.items ?? [],
                ]);
              }}
              row={editItemDialogOpen.row}
              externalTaxTypeId={itemPackage.taxTypeId}
              items={items}
              disableAmountCheck={disableAmountCheck}
              taxes={taxes}
              onSubmit={processEditItem}
              canOverrideGrossPrice={true}
            />
            <SelectServiceDialog
              services={allServices}
              open={addServiceDialog.open}
              serviceToEdit={addServiceDialog.serviceToEdit}
              externalTaxTypeId={itemPackage.taxTypeId}
              taxes={taxes}
              setOpen={(open) => {
                setAddServiceDialog({ open: open, serviceToEdit: null });
              }}
              addToCart={(service) => {
                if (itemPackage.services.find((x) => x.id === service.id)) {
                  setItemPackage({
                    ...itemPackage,
                    services: itemPackage.services.map((x) =>
                      x.id === service.id ? service : x
                    ),
                  });
                } else {
                  setItemPackage({
                    ...itemPackage,
                    services: [...itemPackage.services, service],
                  });
                }
                setAddServiceDialog({ open: false, serviceToEdit: null });
              }}
            />
          </Grid>
          <ConfirmDeleteDialog />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          disabled={
            !(itemPackage.taxTypeId !== null && itemPackage.name.length > 0)
          }
          onClick={() => {
            if (itemPackage.taxTypeId !== null) {
              addToCart(itemPackage);
              setItemPackage({
                id: id,
                name: 'Termékcsomag ' + id,
                amount: 1,
                discount: 0,
                sellPriceGross: 0,
                sellPriceNet: 0,
                taxTypeId: 1,
                comment: '',
                items: [],
                services: [],
              });
            }
          }}
        >
          Hozzáadás
        </Button>
        {/* {selectUnpriced && (
          <Tooltip title="Egyedi tétel hozzáadása">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                let id = cart.map((x) => x.orderId < 0).length + 1;

                setItem({
                  itemId: -id,
                  itemName: 'Gyüjtő ' + id,
                  itemNumber: 'A-' + id.toString().padStart(3, '0'),
                  amount: 1,
                  discount: 0,
                  sellPriceGross: 0,
                  sellPriceNet: 0,
                  itemAlternateName: '',
                  taxTypeId: taxes[0]?.id ?? 0,
                  comment: '',
                  isComplexItem: false,
                  parts: [],
                });
              }}
            >
              Egyedi tétel
            </Button>
          </Tooltip>
        )} */}
        <Button color="primary" variant="outlined" onClick={handleClose}>
          Mégse
        </Button>
      </DialogActions>
    </Dialog>
  );
}
